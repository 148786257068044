@import 'bower_components/fontawesome/scss/variables';
@import 'bower_components/fontawesome/scss/path';
@import 'bower_components/fontawesome/scss/mixins';
@import 'bower_components/fontawesome/scss/icons';

@import 'addons/ember-calendar/paint-core';
@import 'addons/ember-calendar/main';

.is-pok {
  background-color: #181D31;
  color:black;
}

button.is-pok {
  background-color: #181D31;
  color:black
}

.hero-body.is-black {
  background-color: #fff;
}

.is-pok-light {
  background-color: #fff;
}

.is-pok-nav {
  background-color: #333333;
}

nav > div.navbar-brand > a {
  color: black;
  background-color: #474747;
}

nav > div.navbar-brand > div {
  color: black;
}

nav > div.navbar-brand > div > p {
  color: black;
}

nav > div.navbar-brand > a:hover {
  background-color: darkgrey;
  color: white;
}

.as-calendar-occurrence--timetable .as-calendar-occurrence__remove {
  opacity: 1!important;

}

html {
  background-color: #fff !important;
}

.as-calendar-occurrence__title {
  padding-top: 15px !important;
}

.hero-body {
  padding-top: 1rem !important;
}

.as-calendar-occurrence {
  background-color: rgba(40, 167, 68, 0.8) !important;
  border-left: 2px solid gray;
}